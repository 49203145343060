var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('c-box', {
    attrs: {
      "flex-grow": "1"
    }
  }, [_c('c-breadcrumb', {
    attrs: {
      "font-size": "14px",
      "line-height": "21px",
      "separator": "›",
      "d": ['none', 'block']
    }
  }, [_c('c-breadcrumb-item', [_c('c-breadcrumb-link', {
    attrs: {
      "color": "primary.400",
      "as": "router-link",
      "to": "/admin/diet-program"
    }
  }, [_vm._v(" Manajemen Diet Program ")])], 1), _c('c-breadcrumb-item', [_c('c-breadcrumb-link', {
    attrs: {
      "is-current": ""
    }
  }, [_vm._v(" Detail Diet Program ")])], 1)], 1), _c('c-divider', {
    attrs: {
      "my": "16px"
    }
  }), _c('c-flex', {
    attrs: {
      "justify": "space-between",
      "align": "center"
    }
  }, [_c('c-heading', {
    attrs: {
      "as": "h3",
      "font-family": "Montserrat",
      "font-weight": "bold",
      "font-size": "24px",
      "line-height": "36px"
    }
  }, [_vm._v(" " + _vm._s(_vm.item.name) + " ")]), _c('c-box', [_c('c-button', {
    attrs: {
      "variant": "solid",
      "variant-color": "red",
      "border-radius": "60px",
      "min-w": "114px",
      "h": "auto",
      "py": "13px",
      "px": "18px",
      "mr": "20px",
      "font-size": "16px",
      "font-weight": "500",
      "line-height": "24px"
    },
    on: {
      "click": function click($event) {
        _vm.isOpen = true;
      }
    }
  }, [_c('c-image', {
    attrs: {
      "src": require('@/assets/icon-trash.svg'),
      "alt": "file program",
      "mr": "10px",
      "border-radius": "6px"
    }
  }), _vm._v(" Hapus ")], 1), _c('c-button', {
    attrs: {
      "variant": "solid",
      "variant-color": "primary",
      "border-radius": "60px",
      "h": "auto",
      "min-w": "114px",
      "py": "13px",
      "px": "18px",
      "font-size": "16px",
      "font-weight": "500",
      "line-height": "24px",
      "as": "router-link",
      "to": {
        name: 'admin.diet-program.edit',
        params: {
          id: _vm.id
        }
      }
    }
  }, [_c('c-image', {
    attrs: {
      "src": require('@/assets/icon-pencil.svg'),
      "alt": "file program",
      "mr": "10px",
      "border-radius": "6px"
    }
  }), _vm._v(" Edit ")], 1)], 1)], 1), _c('c-text', {
    attrs: {
      "mb": "30px",
      "color": "gray.900"
    }
  }, [_vm._v(" Dibuat: " + _vm._s(_vm.item.createdAt) + " ")]), _c('c-box', [_c('c-grid', {
    attrs: {
      "w": "100%",
      "template-columns": "repeat(2, 1fr)",
      "gap": "6",
      "mb": "77px"
    }
  }, [_c('c-box', [_c('c-form-control', {
    attrs: {
      "mb": "30px"
    }
  }, [_c('c-text', {
    attrs: {
      "mb": "2px",
      "color": "primary.400",
      "font-weight": "500",
      "font-size": "16px",
      "line-height": "24px"
    }
  }, [_vm._v(" Overview Program ")]), _c('c-text', {
    attrs: {
      "color": "superDarkGray.900",
      "font-size": "18px",
      "line-height": "27px"
    }
  }, [_vm._v(" " + _vm._s(_vm.item.overview) + " ")])], 1), _c('c-form-control', {
    attrs: {
      "mb": "30px"
    }
  }, [_c('c-text', {
    attrs: {
      "mb": "2px",
      "color": "primary.400",
      "font-weight": "500",
      "font-size": "16px",
      "line-height": "24px"
    }
  }, [_vm._v(" Deskripsi Program ")]), _c('c-text', {
    attrs: {
      "color": "superDarkGray.900",
      "font-size": "18px",
      "line-height": "27px"
    }
  }, [_vm._v(" " + _vm._s(_vm.item.description) + " ")])], 1), _c('c-form-control', {
    attrs: {
      "mb": "30px"
    }
  }, [_c('c-text', {
    attrs: {
      "mb": "2px",
      "color": "primary.400",
      "font-weight": "500",
      "font-size": "16px",
      "line-height": "24px"
    }
  }, [_vm._v(" Created by ")]), _c('c-text', {
    attrs: {
      "color": "superDarkGray.900",
      "font-size": "18px",
      "line-height": "27px",
      "text-transform": "capitalize"
    }
  }, [_vm._v(" " + _vm._s(_vm.item.createBy ? _vm.item.createBy.firstName + _vm.item.createBy.lastName : "-") + " ")])], 1), _c('c-form-control', {
    attrs: {
      "mb": "30px"
    }
  }, [_c('c-text', {
    attrs: {
      "mb": "2px",
      "color": "primary.400",
      "font-weight": "500",
      "font-size": "16px",
      "line-height": "24px"
    }
  }, [_vm._v(" Program ini cocok untuk ")]), _c('c-text', {
    attrs: {
      "color": "superDarkGray.900",
      "font-size": "18px",
      "line-height": "27px"
    }
  }, [_vm._v(" " + _vm._s(_vm.item.suitableFor) + " ")])], 1), _c('c-form-control', {
    attrs: {
      "mb": "30px"
    }
  }, [_c('c-text', {
    attrs: {
      "mb": "2px",
      "color": "primary.400",
      "font-weight": "500",
      "font-size": "16px",
      "line-height": "24px"
    }
  }, [_vm._v(" Target Program ")]), _c('c-text', {
    attrs: {
      "color": "superDarkGray.900",
      "font-size": "18px",
      "line-height": "27px"
    }
  }, [_vm._v(" " + _vm._s(_vm.item.programTarget) + " ")])], 1), _c('c-form-control', {
    attrs: {
      "mb": "30px"
    }
  }, [_c('c-text', {
    attrs: {
      "mb": "2px",
      "color": "primary.400",
      "font-weight": "500",
      "font-size": "16px",
      "line-height": "24px"
    }
  }, [_vm._v(" Fasilitas Program ")]), _c('c-text', {
    attrs: {
      "color": "superDarkGray.900",
      "font-size": "18px",
      "line-height": "27px"
    }
  }, [_vm._v(" " + _vm._s(_vm.item.serviceFacility) + " ")])], 1)], 1), _c('c-box', [_c('c-form-control', {
    attrs: {
      "mb": "30px"
    }
  }, [_c('c-text', {
    attrs: {
      "mb": "2px",
      "color": "primary.400",
      "font-weight": "500",
      "font-size": "16px",
      "line-height": "24px"
    }
  }, [_vm._v(" Gambar Program ")]), _c('c-box', {
    attrs: {
      "w": "340px",
      "min-w": "340px",
      "h": "404px",
      "bg": "primary.400",
      "overflow": "hidden",
      "border-radius": "6px"
    }
  }, [_c('c-image', {
    attrs: {
      "src": _vm.getPhotoProgram(_vm.item.photoUrl),
      "alt": _vm.item.name,
      "w": "100%"
    }
  })], 1)], 1)], 1)], 1), _c('c-heading', {
    attrs: {
      "as": "h3",
      "font-family": "Montserrat",
      "font-weight": "bold",
      "font-size": "24px",
      "line-height": "36px",
      "color": "primary.400",
      "mb": "25px"
    }
  }, [_vm._v(" Layanan Program ")]), _vm._l(_vm.item.productServices, function (item, index) {
    return _c('c-box', {
      key: 'layanan' + index,
      attrs: {
        "border": "1px solid #008C81",
        "box-sizing": "border-box",
        "border-radius": "12px",
        "py": "25px",
        "px": "30px",
        "mb": "25px"
      }
    }, [_c('c-form-control', {
      attrs: {
        "mb": "30px"
      }
    }, [_c('c-text', {
      attrs: {
        "mb": "2px",
        "color": "primary.400",
        "font-weight": "500",
        "font-size": "16px",
        "line-height": "24px"
      }
    }, [_vm._v(" Nama Layanan ")]), _c('c-text', {
      attrs: {
        "color": "superDarkGray.900",
        "font-size": "18px",
        "line-height": "27px",
        "text-transform": "capitalize"
      }
    }, [_vm._v(" " + _vm._s(item.programsService) + " ")])], 1), _c('c-form-control', {
      attrs: {
        "mb": "30px"
      }
    }, [_c('c-text', {
      attrs: {
        "mb": "2px",
        "color": "primary.400",
        "font-weight": "500",
        "font-size": "16px",
        "line-height": "24px"
      }
    }, [_vm._v(" Durasi Layanan ")]), _c('c-text', {
      attrs: {
        "color": "superDarkGray.900",
        "font-size": "18px",
        "line-height": "27px",
        "text-transform": "capitalize"
      }
    }, [_vm._v(" " + _vm._s(item.duration) + " hari ")])], 1), _c('c-form-control', {
      attrs: {
        "mb": "30px"
      }
    }, [_c('c-text', {
      attrs: {
        "mb": "2px",
        "color": "primary.400",
        "font-weight": "500",
        "font-size": "16px",
        "line-height": "24px"
      }
    }, [_vm._v(" Harga ")]), _c('c-text', {
      attrs: {
        "color": "superDarkGray.900",
        "font-size": "18px",
        "line-height": "27px",
        "text-transform": "capitalize"
      }
    }, [_vm._v(" Rp" + _vm._s(Number(item.price).toLocaleString("id")) + " ")])], 1), _c('c-form-control', {
      attrs: {
        "mb": "30px"
      }
    }, [_c('c-text', {
      attrs: {
        "mb": "2px",
        "color": "primary.400",
        "font-weight": "500",
        "font-size": "16px",
        "line-height": "24px"
      }
    }, [_vm._v(" Target Layanan ")]), _c('c-text', {
      attrs: {
        "color": "superDarkGray.900",
        "font-size": "18px",
        "line-height": "27px"
      }
    }, [_vm._v(" " + _vm._s(item.serviceTarget) + " ")])], 1)], 1);
  })], 2), _c('DeleteConfirm', {
    attrs: {
      "z-index": "1000",
      "content": _vm.deletedText,
      "is-open": _vm.isOpen
    },
    on: {
      "on-delete": _vm.onDelete
    }
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }